import * as React from 'react';
import classnames from 'classnames';

import { clsHelper } from '../../utilities/class-name-helper';

import { getCatalogId, RichTextComponent } from '@msdyn365-commerce/core';
import { ICartFromUrlData } from './cart-from-url.data';
import { ICartFromUrlProps } from './cart-from-url.props.autogenerated';

import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Modal, ModalBody, ModalHeader } from '@msdyn365-commerce-modules/utilities';

import { addCartLinesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';

import { attrNames } from '../../utilities/global-constants';
import { Cart } from '@msdyn365-commerce/retail-proxy';

//==============================================================================
// INTERFACES
//==============================================================================
interface ProductAddRequest {
    recId: number
    quantity: number
}

//==============================================================================
// CLASS NAME UTILITY
//==============================================================================
const BASE_CLASS = 'cart-from-url';
const cls = (fragment: string) => clsHelper(BASE_CLASS, fragment);

/**
 *
 * CartFromUrl component
 * @extends {React.Component<ICartFromUrlProps<ICartFromUrlData>>}
 */
@observer
class CartFromUrl extends React.Component<ICartFromUrlProps<ICartFromUrlData>> {
    @observable private isModalOpen: boolean = false;
    @observable private isLoading: boolean = this.props.context.request.query && this.props.context.request.query['cart'] ? true : false;

    //----------------------------------------------------------
    //----------------------------------------------------------
    constructor(props: ICartFromUrlProps<ICartFromUrlData>) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.addUrlItemsToCart = this.addUrlItemsToCart.bind(this);
    }

    //------------------------------------------------------
    //------------------------------------------------------
    public componentDidMount(): void {
        const { context, data } = this.props;
        const query = context.request.query || {};

        if (query['cart']) {
            const cartData = data.cart.result;

            if (cartData?.cart.CartLines?.length) {
                this.isModalOpen = true;
            } else {
                this.addUrlItemsToCart();
            }
        }
    }

    //----------------------------------------------------------
    //----------------------------------------------------------
    public render(): JSX.Element | null {
        return (
            <>
                {this.renderLoadingPopup()}
                {this.renderModal()}
            </>
        );
    }

    //----------------------------------------------------------
    //----------------------------------------------------------
    public renderRemoveFromCart(): JSX.Element | null {
        if (!this.props.data.cart.result?.cart.CartLines?.length) {
            return null;
        }

        return (
            <button className='empty-cart__button' onClick={() => {
                const cartLineIds = this.props.data.cart.result?.cart.CartLines?.map(line => {
                    return line.LineId || '';
                }) || [];
                this.props.data.cart.result?.removeCartLines({cartLineIds: cartLineIds}).then(result => {
                    this.toggleModal();
                    this.addUrlItemsToCart();
                });
            }}>
                {this.props.resources.emptyCartButtonLabel}
            </button>
        );
    }

    //----------------------------------------------------------
    //----------------------------------------------------------
    private async addUrlItemsToCart() {
        const cartData = this.props.data.cart.result;
        const query = this.props.context.request.query || {};
        const cartItems = query.cart;
        const cartOrg = query.organization;

        // Parse the request
        const cartRequest = this.parseCartRequest(cartItems);

        // Ensure there's at least a single product in the request
        if (cartRequest.length > 0) {

            const umbrellaGroup = query.umbrella;

            const newCart = cartData?.cart as Cart;

            if (!newCart?.AttributeValues?.length) {
                newCart.AttributeValues = [];
            }

            const schoolId = newCart?.AttributeValues?.find(atr => {
                return atr.Name === attrNames.schoolIdPP;
            });

            if (schoolId) {
                schoolId['TextValue'] = cartOrg;
            } else {
                newCart.AttributeValues.push(
                    {
                        // @ts-expect-error
                        "@odata.type": "#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue",
                        "Name": attrNames.schoolIdPP,
                        "TextValue": cartOrg,
                        "TextValueTranslations": [],
                        "ExtensionProperties": []
                    }
                );
            }

            await cartData?.updateCart({newCartObject: newCart});

            // Add items to the cart
            await addCartLinesAsync({ callerContext: this.props.context.actionContext}, cartData?.cart.Id || '', cartRequest.map(entry => ({
                CatalogId: getCatalogId(this.props.context.request),
                EntryMethodTypeValue: 3,
                ProductId: entry.recId,
                Quantity: entry.quantity,
                AttributeValues: [
                    {
                        '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue',
                        Name: attrNames.umbrellaGroup,
                        TextValue: umbrellaGroup,
                        ExtensionProperties: [],
                        TextValueTranslations: []
                    }
                ]
            })));

            window.location.href = window.location.href.split('?')[0];
            this.isLoading = false;
        }
    }

    //==========================================================
    // Parse requests passed in via query parameter
    //
    // Format: recId:qty,recId:qty,...
    //==========================================================
    private parseCartRequest(request: string): ProductAddRequest[] {

        // Split the string into a series of item/quantity pairs and process each one
        return request.split(',').reduce(
            (output: ProductAddRequest[], entry) => {

                // Split item record ID and quantity
                const [recId, qty] = entry.split(':');

                // Add to the list if the rec ID and quantity are valid
                if (+recId && +qty) {
                    output.push({
                        recId: +recId,
                        quantity: +qty,
                    });
                }
                return output;
            },
            []
        );
    }

    //----------------------------------------------------------
    //----------------------------------------------------------
    private renderModal() {
        const hasSlots = !!this.props.slots?.modalContent?.length;
        // Render the modal
        return (
            <Modal isOpen={this.isModalOpen}>
                <ModalHeader>
                    {this.props.config.cartFromUrlModalHeader}
                </ModalHeader>
                <ModalBody>
                    <div className={BASE_CLASS}>
                        {hasSlots && this._renderSlotItems(this.props.slots.modalContent)}
                        {this.renderRemoveFromCart()}
                        <div className={cls('buttons')}>
                            <button className={classnames(cls('button'), 'merge')} onClick={() => { this.toggleModal(); this.addUrlItemsToCart(); }}>{this.props.resources.cartFromUrlModalButton}</button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    //------------------------------------------------------
    //------------------------------------------------------
    private readonly _renderSlotItems = (items: React.ReactNode[]): JSX.Element => {
        return (
            <div className={cls('slots')}>
                {items.map((slot: React.ReactNode, index: number) => (
                    <React.Fragment key={index}>
                        {slot}
                    </React.Fragment>
                ))}
            </div>
        );
    };

    //----------------------------------------------------------
    //----------------------------------------------------------
    private toggleModal() {
        this.isModalOpen = !this.isModalOpen;
    }

    //----------------------------------------------------------
    //----------------------------------------------------------
    private renderLoadingPopup() {
        const { config, resources } = this.props;

        return (
            <Modal isOpen={this.isLoading}>
                <ModalBody>
                    <div className={cls('loading')}>
                        {<RichTextComponent text={config.loadingLabel || resources.loadingLabel} />}
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

export default CartFromUrl;
